body {
    font-family: Arial, sans-serif;
}

.hidden {
    display: none;
  }
  
  .show-search-bar {
    display: block;
  }
    
.search-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.search-wrap {
    text-align: center;
}

.search-form input[type="search"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
}

.search-form button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.search-form button[type="submit"]:hover {
    background-color: #0056b3;
}
